// var $ = require('jquery');

// module.exports = function() {
    $(function() {
        $('.toggle-sidebar').on('click', toggleNav);
        // when user type esc, close the sidebar too =)
        $(document).keyup(function(e) {
            if (e.keyCode == 27) { // esc key
                if ($('#wrapper').hasClass('show-nav')) {
                    toggleNav();
                }
            }
        });

        // show something when an input is focused
        $('.input-toggle').on('focus', function() {
            target = $(this).data('target');
            $(target).slideDown();
        });
    });

    /**
     * Toggle sidebar
     *
     * @return {void}
     */
    function toggleNav() {
        if ($('#wrapper').hasClass('show-nav')) {
            $('#wrapper, #sidebar').removeClass('show-nav');
        } else {
            $('#wrapper, #sidebar').addClass('show-nav');
        }

        $('.toggle-sidebar').find('i').toggleClass('fa-angle-left fa-bars');
    }
// };