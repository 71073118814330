$('.show-target').on('click', function() {
    var target = $(this).data('target');
    $(target).slideDown();
});

$('.clickable-row').on('click', function(e) {
    window.document.location = $(this).data("href");
});

$('.Switch__input').on('click', function() {
    $('.clickable-row').toggleClass('hide');
    // $('.clickable-row.beta').toggleClass('hide');
});

$('.toggle-search').on('click', function() {
    $('.GlobalSearch').slideToggle('fast');
});
$(document).keyup(function(e) {
    if (e.keyCode == 27) {
        if ($('.GlobalSearch').is(':visible')) {
            $('.GlobalSearch').slideUp('fast');
        }
    }
});

$('.ConfirmBeforeDelete').on('click', function(event) {
    event.preventDefault();
    swal({
        title: "Tem certeza?",
        text: "Você não poderá recuperar esse arquivo novamente!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, Deletar!",
        closeOnConfirm: false
    }, function() {
        window.location = event.currentTarget.href;
    });
});